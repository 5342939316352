import React from 'react'
import { Button, Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handlePreview } from 'util/OpenPreviewWindow/OpenPreviewWindow';
const SocialIcons = () => {
    const social_links = [
        {
            className: "btn-link font-size-lg rounded-sm d-40 btn-icon text-facebook btn-animated-icon",
            label: "Facebook",
            link: "https://www.facebook.com/kargoplex/",
            icon: { "style": "fab", "name": "facebook" }
        },
        {
            className: "btn-link font-size-lg rounded-sm d-40 btn-icon text-twitter btn-animated-icon",
            label: "Twitter",
            link: "https://twitter.com/kargoplex",
            icon: { "style": "fab", "name": "twitter" }
        },
        {
            className: "btn-link font-size-lg rounded-sm d-40 btn-icon text-instagram btn-animated-icon",
            label: "Instagram",
            link: "https://www.instagram.com/kargoplex",
            icon: { "style": "fab", "name": "instagram" }
        },
        {
            className: "btn-link font-size-lg rounded-sm d-40 btn-icon text-twitter btn-animated-icon",
            label: "Linkedin",
            link: "https://www.linkedin.com/company/kargoplex/",
            icon: { "style": "fab", "name": "linkedin" }
        }
    ]


    return (
        <div className="hero-footer">
            {social_links.map((item, index) => (
                <Tooltip title={item.label} arrow>
                    <Button
                        className={item.className}
                        onClick={() => handlePreview(item.link)}
                    >
                        <span className="btn-wrapper--icon d-flex">
                            <FontAwesomeIcon icon={[item.icon.style, item.icon.name]} />
                        </span>
                    </Button>
                </Tooltip>
            ))}
        </div>
    )
}

export default SocialIcons