import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFileExtensionIcon } from 'util/Utils';
import { Button, Tooltip } from '@material-ui/core';
import { handlePreview } from 'util/OpenPreviewWindow/OpenPreviewWindow';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import imgIcon from 'assets/svg/imgIcon.png'

const styles = (theme) => ({});

const DocPreview = ({ classes, document, handleDelete, index, showActionButton = true }) => {


    const [openMoreOptions, setOpenMoreOptions] = useState(null);

    const handleCloseOpenMoreOption = () => {
        setOpenMoreOptions(null);
    }
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    useEffect(() => {
        
        setScreenWidth(window.innerWidth)
    },[window.innerWidth])

    return (
        <>
            <Tooltip
                placement="top"
                title={document.name}
                arrow
                classes={{ tooltip: 'tooltip-kargo-blue' }}>
                <motion.a
                    style={{
                        minWidth: screenWidth > 700 ? "100%" : "200px",
                        width:"100%",
                        maxWidth:"400px"
                    }}
                    className="card bg-light btn hover-scale-sm rounded text-left p-4">
                    <div className="d-flex align-items-center">
                        <div className="mr-3 text-center ">
                            <img
                                className="d-40"
                                src={getFileExtensionIcon(`abc.${document.extension}`) === "image" ? document.url : getFileExtensionIcon(`abc.${document.extension}`)}
                                alt="download file"
                            />
                        </div>
                        <div className="flex-grow-1 text-black-50 w-65  pr-2">
                            <div className="font-size-sm text-black text-truncate">
                                {document.name}
                            </div>
                        </div>
                        {showActionButton ?
                            <IconButton
                                onClick={(e) => {
                                    setOpenMoreOptions(e.currentTarget)
                                }}
                            >
                                <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                            </IconButton>
                            :
                            <FontAwesomeIcon onClick={(e) => {
                                handlePreview(document.url, 'document')
                            }} icon={['fas', 'arrow-right']} />
                        }

                        {/* </Button> */}
                    </div>
                </motion.a>
            </Tooltip>


            <SmallPopupWrapper
                open={openMoreOptions}
                onClose={handleCloseOpenMoreOption}
            >
                <MenuItem onClick={() => { handleCloseOpenMoreOption(); handlePreview(document.url, 'document') }}>Preview</MenuItem>
                <MenuItem onClick={() => { handleCloseOpenMoreOption(); handleDelete(index) }}> Remove</MenuItem>
            </SmallPopupWrapper>
        </>
    );
};

DocPreview.defaultProps = {};

DocPreview.propTypes = {
    classes: PropTypes.object.isRequired,
    document: PropTypes.object.isRequired
};

export default withStyles(styles)(DocPreview);
