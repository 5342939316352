import { Button, Divider, Popover } from '@material-ui/core';
import Style from './Card.module.css';
import Status from './Status/Status';
import { useHistory } from 'react-router-dom';
import { makeOfferInfo, setActiveReservation } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ForwarderTeam from 'service/ForwarderTeam';
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import { notify } from 'sites/shipper/components/Toast/toast';
import { ForwarderService, ShipmentLeadsService, ShipmentsService } from 'service';
import RightDrawer from 'sites/shipper/components/layout/RightDrawer';
import ShipmentCardPreview from '../../Shipments/ShipmentCardPreview';
import ErrorPopUp from './ErrorPopUp/ErrorPopUp';
import ModalPopupWrapper from 'components/Common/ModalPopup/ModalPopup';
import FromTo from 'components/Common/from_to/FromToVert';
import LoaderComponent from '../../../components/LoaderComponent';
import { userPermission } from 'util/forwarderPermissionRoles';
import { getCurrencyIcon } from 'util/Shipment/shipment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomAvatar from 'sites/shipper/components/CustomAvatar/CustomAvatar';
import { renderShipmentAddress,loadDisplay } from 'util/Shipment/shipmentLoads';

/* eslint-disable */

const Card = ({ data, idx, make, isPublic = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [services, setServices] = useState([]);
  useEffect(() => {
    const array = [];
    data.services.map((item) => {
      array.push(item.serviceName);
    });
    setServices(array);
  }, [data?.services]);
  const auth = useSelector((state) => state.session.auth);


  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const activeShipment = useSelector(
    (state) => state.dashboard.activeReservation
  );
  const [openRightDrawer, setOpenRightDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const toogleRightDrawer = (bool) => {
    setOpenRightDrawer(bool);
    if (!bool) {
      dispatch(setActiveReservation(null));
    }
  };
  const acceptShipment = () => {
    if (isPublic) {
      history.push('/login');
      return
    }
    if (!data.canAccept) {
      setOpenModal(true);
      return;
    }
    setLoading(true)
    ShipmentLeadsService.acceptShipmentLeadLead(
      auth.accountId,
      data._id,
      auth.token
    ).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
        setLoading(false)
        return;
      }
      notify(dispatch, 'success', res.message);
      history.push(`/shipment-detail/${data._id}`);
    });
  };

  useEffect(() => {
    dispatch(setActiveReservation(null));
  }, []);






  const handleViewDetails = () => {
    if (isPublic) {
      return
    }
    dispatch(setActiveReservation({ _id: data._id }));
    setOpenRightDrawer(true);
    // setLoading(true)
    // const data_to_send = {
    //   forwarder_id: auth.accountId,
    //   shipment_id: data._id
    // }
    // ShipmentsService.fetchShipmentOverviewDetail(data_to_send, auth.token).then(res => {
    //   if (res.error) {
    //     console.log(res.error)
    //     notify(dispatch, "error", res.error)
    //   } else {
    //     // dispatch(setActiveReservation(res));
    //     // setOpenRightDrawer(true);
    //   }
    //   setLoading(false)
    // })
  };






  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const extraServicePopover = Boolean(anchorEl);









  const screenWidth = window.innerWidth;


  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      {/* <ModalPopupWrapper
                maxWidth="lg"
                open={openModal}
            > */}
      <ErrorPopUp
        open={openModal}
        error={`You have not activated the services requested by the shipper in ${data?.origin?.city}, ${data?.origin?.country} | ${data?.origin?.port}. To accept this shipment, go to my service section and enable the service requested`}
        onSubmit={() => {
          history.push('/services');
        }}
        onCancel={() => setOpenModal(false)}
      />
      {/* </ModalPopupWrapper> */}

      <div className={Style.container}>
        <div className={Style.top_container}>
          <div className={Style.user_detail}>
            <div>
              {isPublic ?
                <img
                  src={!isPublic ? data?.shipper_avatar : data?.carrier_id?.logo}
                  alt="img"
                  style={{ width: '30px' }}
                />
                :
                <CustomAvatar
                  height={35} width={35}
                  name={data?.shipper_name}
                  src={data?.shipper_avatar}
                />
              }

            </div>
            <div className={Style.flex_col}>
              <span className={Style.bold}>{!isPublic ? data?.shipper_name : data?.carrier_id?.name}</span>
              {!isPublic && <Status label="Verified Shipper" />}
            </div>
          </div>
          <div className={Style.flex_col}>
            <span className={Style.top_bar_label}>Shipment Id</span>
            <span className={Style.top_bar_value}>{data?.booking_id}</span>
          </div>
          {!isPublic &&
            <div className={Style.flex_col}>
              <span className={Style.top_bar_label}>Price</span>
              <span className={Style.top_bar_value}>{getCurrencyIcon(data?.currency)}{data?.price} {data?.currency}</span>
            </div>
          }
        </div>

        <Divider />

        <div className={Style.middle_container}>
          <div
            // className={`c-p-1 c-rounded c-flex-col`}
            style={{
              // gap: '0.3em',
              // height: 'fit-content',
              minWidth: screenWidth > 600 ? "300px" : "max-content",
              padding: "0px"
              // background: '#F8F9FF'
            }}
          >
            {/* <FromTo
              origin={`${data?.origin?.city}, ${data?.origin?.country} | ${data?.origin?.port}`}
              destination={`${data?.destination?.city}, ${data?.destination?.country} | ${data?.destination?.port}`}
              origin_country_code={data?.origin?.country}
              destination_country_code={data?.destination?.country}
            /> */}
            <FromTo
              origin={renderShipmentAddress(data?.origin)}
              origin_country_code={data?.origin?.country?.code}
              destination={renderShipmentAddress(data?.destination)}
              destination_country_code={data?.destination?.country?.code}
            />
          </div>

          <div className={`w-50 ${Style.middle_details}`}>
            <span className={Style.middle_details_label}>Transport Mode :</span>
            <span className={Style.middle_details_value}>{data?.transport_mode} </span>
            <span className={Style.middle_details_label}>Shipping Line :</span>
            <span className={`${Style.middle_details_value} text-truncate`}>{data?.carrier_id?.name}</span>
            <span className={Style.middle_details_label}>Ready for collection :</span>
            <span className={Style.middle_details_value}>{data?.ready_date}</span>
            <span className={Style.middle_details_label}>Load Type :</span>
            <span className={Style.middle_details_value}> {loadDisplay(data?.load)}</span>
            <span className={Style.middle_details_label}>Created on :</span>
            <span className={Style.middle_details_value}><FontAwesomeIcon icon={['fa', `clock`]} /> {data?.createdAt}</span>

          </div>



        </div>

        <Divider />

        <div className={Style.bottom_container}>
          <div className={Style.flex_col}>
            <span className={Style.bold}>Services Requested</span>
            <div
              className={Style.flex}
              style={{ gap: '0.2rem 1rem', flexWrap: 'wrap' }}>
              {/* {data?.services.custom_brokerage && 
                        <Status label="Custom Clearance" />
                        } */}
              {services.length > 0 ? (
                <>
                  {services.slice(0, 2).map((item, index) => (
                    <Status label={item} key={index} />
                  ))}
                  {services.length > 2 && (
                    <>
                      <span
                        onClick={() =>
                          handleViewDetails()
                        }
                        className={Style.more_services}
                        aria-owns={
                          extraServicePopover ? 'mouse-over-popover' : undefined
                        }
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}>
                        +{services.length - 2}
                      </span>
                      <Popover
                        id="mouse-over-popover"
                        style={{
                          pointerEvents: 'none'
                        }}
                        className={`d-flex c-p-1 flex-wrap c-gap-1`}
                        open={extraServicePopover}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus>
                        {services.slice(2).map((item, index) => (
                          <span key={index} className={`font-size-xs m-2`}>
                            {item},
                          </span>
                        ))}
                      </Popover>
                    </>
                  )}
                </>
              ) : (
                <span style={{ fontSize: '12px' }}>No Services Selected</span>
              )}
              {/* {data?.services?.} */}
            </div>
          </div>
          <div
            className={Style.flex}
            style={{ gap: '1rem', alignItems: 'center' }}>
            {make ? (
              <>
                <>
                  {!isPublic &&
                    <Button
                      variant="outlined"
                      className={Style.make_offer_btn}
                      // onClick={handleMakeoffer}
                      onClick={handleViewDetails}>
                      View Details
                    </Button>
                  }
                  {isPublic &&
                    <Button
                      variant="outlined"
                      className={Style.make_offer_btn}
                      // onClick={handleMakeoffer}
                      onClick={acceptShipment}>
                      View Price
                    </Button>
                  }
                  {!isPublic && userPermission.shipment_leads.edit.includes(auth?.accountCmpyLinkType) && (
                    <Button
                      variant="outlined"
                      className={Style.make_offer_btn}
                      // onClick={handleMakeoffer}
                      onClick={() => setConfirmDialogOpen(true)}>
                      Accept Shipment
                    </Button>
                  )}
                </>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  className={Style.make_offer_btn}
                // onClick={acceptShipment}
                >
                  View Offer
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      <ConfirmDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        confirmDialogFunction={acceptShipment}
        question={`Are you sure you want to accept this shipment`}
      />

      {
        activeShipment && (
          <RightDrawer
            onClose={() => toogleRightDrawer(false)}
            open={openRightDrawer}
            widthClasses={Style.res_right_drawer}
            closeBtnClasses={Style.right_drawer_close_btn}>
            <ShipmentCardPreview
              unAssignedShipment={true}
              booking={activeShipment}
            />
          </RightDrawer>
        )
      }
    </>
  );
};

/* eslint-enable */
export default Card;
