import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

const ModalSimple = ({
  modalTitle,
  modalDescription,
  children,
  open,
  scroll,
  handleClose,
  action,
  maxWidth,
  actionButtonText = "Save",
  disabled = false,
  fullWidth
}) => {
  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleModalClose = () => {
    handleClose();
  };

  const handleAction = () => {
    action();
  };

  return (
    <Dialog
      classes={{
        paper: 'modal-content rounded-lg'
      }}
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      aria-labelledby="form-dialog-title">
      {modalTitle && (
        <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
      )}
      <DialogContent className="p-4">
        {modalDescription && (
          <DialogContentText>{modalDescription}</DialogContentText>
        )}

        {children}
      </DialogContent>
      <DialogActions className="p-4">
        <Button
          onClick={handleModalClose}
          size="small"
          variant="text"
          // className="bg-white-10 text-black mr-3 shadow-none"
          className="btn-outline-dark mr-3"
        >
          Cancel
        </Button>
        {action &&
          <Button
            size="small"
            variant="contained"
            disabled={disabled}
            onClick={handleAction} className="btn-primary shadow-none py-2">
            {actionButtonText}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

ModalSimple.defaultProps = {
  scroll: 'paper',
  maxWidth: 'md',
  fullWidth:false
};

ModalSimple.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  scroll: PropTypes.oneOf(['paper', 'body']),
  handleClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false])
};

export default ModalSimple;
