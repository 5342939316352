import React, { useState } from 'react'
import Style from "./AnimatedSearchbar.module.css";
import { InputAdornment, TextField } from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import clsx from 'clsx';



const AnimatedSearchbar = ({
    type = 'text',
    onChange,
    placeholder,
    name,
    value,
    children
}) => {

    const [searchOpen, setSearchOpen] = useState(false);

    const openSearch = () => setSearchOpen(true);
    const closeSearch = () => setSearchOpen(false);


    return (
        <div className={Style.search_bar}>
            <div className="w-100">
                <div
                    className={clsx(
                        'search-wrapper search-wrapper--alternate search-wrapper--grow',
                        {
                            'is-active': searchOpen
                        }
                    )}>
                    <TextField
                        onChange={onChange}
                        placeholder={placeholder ? placeholder : 'Search documents...'}
                        type={type ? type : 'text'}

                        value={value}
                        variant="outlined"
                        size="small"
                        id="input-with-icon-textfield22-2"
                        onFocus={openSearch}
                        onBlur={closeSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchTwoToneIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
            </div>
            <div style={{width:"300px"}} className='d-flex c-gap-half justify-content-end'>
                {children}
            </div>
        </div>
    )
}

export default AnimatedSearchbar