import React, { useEffect, useState } from 'react';
import Style from './ShipmentFilter.module.css';
import {
  Button,
  Card,
  DialogActions,
  Tooltip,
  Typography
} from '@material-ui/core';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CountrySelectNew from 'components/CountrySelect/CountrySelectNew';
import StateSelect from 'components/CountrySelect/StateSelect';
import CitySelect from 'components/CountrySelect/CitySelect';
import PortCodeSelect from 'components/CountrySelect/PortCodeSelect';
import { Tabs, Tab } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  TRANSPORT_MODES,
  TRANSPORT_MODES_LIST,
  fetchCargoCategories
} from 'util/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConstantValuesService from 'service/ConstantValuesService';
import { setFilterDataInRedux } from 'actions';
import { useDispatch } from 'react-redux';
import DropdownOptions from '../../ShippingLeads/Filter/DropdownOptions';
import StyledInput from 'components/StyledInput';
const ShipmentFilter = ({
  filterData,
  setFilterData,
  onClose,
  handleFilter,
  resetFilter
}) => {
  const dispatch = useDispatch();
  console.log(new Date(filterData.ready_date_from));
  const [loading, setLoading] = useState(false);
  const [transportLineOption, setTransportLineOption] = useState([]);

  useEffect(() => {
    setLoading(true);
    ConstantValuesService.fetchTransportLine('SEA').then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        const array = [];
        res.length > 0 &&
          res.map((item) => {
            array.push({
              value: item._id,
              label: item.fullName
            });
          });
        setTransportLineOption(array);
      }
      setLoading(false);
    });
  }, []);

  const handleCarrierSelection = (e) => {
    const value = e.target.value;
    if (filterData.carriers.includes(value)) {
      const newCarriers = filterData.carriers.filter((item) => item !== value);
      setFilterData({ ...filterData, carriers: newCarriers });
    } else {
      const newCarriers = [...filterData.carriers, value];
      setFilterData({ ...filterData, carriers: newCarriers });
    }
  };
  const handleStatusSelection = (e) => {
    const value = e.target.value
    if (filterData.statusArry.includes(value)) {
      const newCarriers = filterData.statusArry.filter((item) => item !== value)
      setFilterData({ ...filterData, statusArry: newCarriers })
    } else {
      const newCarriers = [...filterData.statusArry, value]
      setFilterData({ ...filterData, statusArry: newCarriers })
    }
  }
  const handleCountry = (opt, name) => {
    console.log(opt);
    if (opt) {
      setFilterData({
        ...filterData,
        [name]: {
          ...filterData[name],
          country: opt,
          state: '',
          city: '',
          port: ''
        }
      });
    } else {
      setFilterData({
        ...filterData,
        [name]: {
          ...filterData[name],
          country: '',
          state: '',
          city: '',
          port: ''
        }
      });
    }
  };

  const handleState = (opt, name) => {
    if (opt) {
      setFilterData({
        ...filterData,
        [name]: {
          ...filterData[name],
          state: opt,
          city: '',
          port: ''
        }
      });
    } else {
      setFilterData({
        ...filterData,
        [name]: {
          ...filterData[name],
          state: '',
          city: '',
          port: ''
        }
      });
    }
  };

  const handleCity = (opt, name) => {
    if (opt) {
      setFilterData({
        ...filterData,
        [name]: {
          ...filterData[name],
          city: opt,
          port: ''
        }
      });
    } else {
      setFilterData({
        ...filterData,
        [name]: {
          ...filterData[name],
          city: '',
          port: ''
        }
      });
    }
  };

  const handlePort = (opt, name) => {
    if (opt) {
      setFilterData({
        ...filterData,
        [name]: {
          ...filterData[name],
          port: opt
        }
      });
    } else {
      setFilterData({
        ...filterData,
        [name]: {
          ...filterData[name],
          port: ''
        }
      });
    }
  };

  // const resetFilter = () => {
  //   setFilterData({
  //     ...filterData,
  //     keyword: '',
  //     ready_date_from: '',
  //     ready_date_to: '',
  //     origin: {
  //       country: '',
  //       state: '',
  //       city: '',
  //       port: ''
  //     },
  //     destination: {
  //       country: '',
  //       state: '',
  //       city: '',
  //       port: ''
  //     },
  //     load_type: '',

  //     container_type: '',
  //     loads: [],
  //     transportation_mode: [],
  //     carriers: []
  //   });
  //   dispatch(setFilterDataInRedux(null));
  // };

  return (
    <>
      <div className={Style.main}>
        <div className={`w-100 my-2 d-flex align-items-center ${Style.Header}`}>
          <div className="d-flex align-items-center">
            <FilterAltOutlinedIcon />
            <span className="font-weight-bold font-size-lg">Filters</span>
          </div>
          <Button
            className="btn-link btn-link-danger p-0"
            // className={Style.resetButton}
            onClick={resetFilter}>
            Reset
          </Button>
        </div>

        <div className="divider my-3" />

        <div>
          <span className="font-size-md font-weight-bold">Ready Date</span>
        </div>
        <div className={Style.TwoFieldContainer}>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              variant="outlined"
              size="small"
              className={Style.DatePicker}
              label="From"
              value={dayjs(filterData.ready_date_from)}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }
                setFilterData({
                  ...filterData,
                  ready_date_from: newValue.format('MMM D, YYYY')
                });
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              variant="outlined"
              size="small"
              className={Style.DatePicker}
              label="To"
              value={dayjs(filterData.ready_date_to)}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }
                setFilterData({
                  ...filterData,
                  ready_date_to: newValue.format('MMM D, YYYY')
                });
              }}
            />
          </LocalizationProvider> */}
          <StyledInput
            label="From"
            type="date"
            value={filterData.ready_date_from}
            onChange={(e) => {
              if (!e.target.value) {
                return;
              }
              setFilterData({
                ...filterData,
                ready_date_from: e.target.value
              });
            }}
            InputProps={{
              placeholder: filterData.ready_date_from ? undefined : 'From'
            }}
            InputLabelProps={{
              shrink: true
            }}
          />

          <StyledInput
            type="date"
            label="To"
            value={filterData.ready_date_to}
            onChange={(e) => {
              if (!e.target.value) {
                return;
              }
              setFilterData({
                ...filterData,
                ready_date_to: e.target.value
              });
            }}
            InputProps={{
              placeholder: filterData.ready_date_to ? undefined : 'To'
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <div className="divider my-3" />

        <div>
          <span className="font-size-md font-weight-bold">Origin</span>
        </div>
        <div className={Style.TwoFieldContainer} style={{ gridTemplateColumns: "1fr" }}>
          <CountrySelectNew
            useDefault
            isClearable={true}
            isMulti={false}
            onChange={(e, opt) => handleCountry(opt, 'origin')}
            showError={false}
            value={filterData.origin.country}
          />
          <StateSelect
            country={filterData.origin.country?.code}
            value={filterData.origin.state}
            showError={false}
            handleChange={(e, opt) => handleState(opt, 'origin')}
          />
          {/* <CitySelect
            value={filterData.origin.city}
            state={filterData.origin.state?.code}
            showError={false}
            handleChange={(e, opt) => handleCity(opt, 'origin')}
          /> */}
          <PortCodeSelect
            value={filterData.origin.port}
            city={filterData.origin.state?.code}
            showError={false}
            handleChange={(e, opt) => handlePort(opt, 'origin')}
          />
        </div>





        <div className="divider my-3" />

        <div>
          <span className="font-size-md font-weight-bold">Destination</span>
        </div>
        <div className={Style.TwoFieldContainer} style={{ gridTemplateColumns: "1fr" }}>
          <CountrySelectNew
            useDefault
            isClearable={true}
            isMulti={false}
            onChange={(e, opt) => handleCountry(opt, 'destination')}
            showError={false}
            value={filterData.destination.country}
          />
          <StateSelect
            country={filterData.destination.country?.code}
            value={filterData.destination.state}
            showError={false}
            handleChange={(e, opt) => handleState(opt, 'destination')}
          />
          {/* <CitySelect
            value={filterData.destination.city}
            state={filterData.destination.state?.code}
            showError={false}
            handleChange={(e, opt) => handleCity(opt, 'destination')}
          /> */}
          <PortCodeSelect
            value={filterData.destination.port}
            city={filterData.destination.state?.code}
            showError={false}
            handleChange={(e, opt) => handlePort(opt, 'destination')}
          />
        </div>



        <div className="divider my-3" />

        <div>
          <span className="font-size-md font-weight-bold">Shipment Status</span>
        </div>
        <div className={Style.TwoFieldContainer} style={{ gap: '10px' }}>
          {[
            { label: "Unassigned", value: "unassigned" },
            { label: "Active", value: "active" },
            { label: "Pending", value: "pending" },
            { label: "Completed", value: "completed" },
            { label: "Cancelled", value: "cancelled" },
          ].map((option, index) => (
            <Tooltip
              placement="top"
              title={option.label}
              arrow
              style={{ width: 'max-content' }}
              classes={{ tooltip: 'tooltip-kargo-blue' }}>
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px'
                }}>
                <input
                  type="checkbox"
                  value={option.value}
                  checked={filterData.statusArry.includes(option.value)}
                  onChange={handleStatusSelection}
                />
                <span className="font-size-sm text-black text-truncate">
                  {option.label}
                </span>
              </label>
            </Tooltip>
          ))}
        </div>




        <div className="divider my-3" />

        <div>
          <span className="font-size-md font-weight-bold">
            Select Transporation Method
          </span>
        </div>
        <div className='mt-2'>
          {TRANSPORT_MODES_LIST &&
            TRANSPORT_MODES_LIST.map((item, index) => (
              <DropdownOptions
                filters={filterData}
                setFilters={setFilterData}
                id={`slider-${index}`}
                header={item.label}
                iconSrc={`${item.label.toLowerCase()}`}
                options={fetchCargoCategories(item)}
              />
            ))}
        </div>
        {/* <div>
            <span className="font-size-md font-weight-bold">
              Transporation Mode
            </span>
          </div>
          <div className={Style.OneFieldContainer}>
            <Tabs
              className="nav-tabs-primary ship-mode-tabs"
              value={filterData.transportation_mode}
              variant="fullWidth"
              onChange={(e, newValue) => {
                // console.log(TRANSPORT_MODES[newValue].value)
                setFilterData({
                  ...filterData,
                  transportation_mode: TRANSPORT_MODES[newValue].value
                });
              }}
              classes={{ scroller: 'overflow-visible' }}>
              {TRANSPORT_MODES_LIST.map((mode, index) => (
                <Tab
                  key={index}
                  value={mode.value}
                  selected={mode.value === filterData.transportation_mode}
                  className="py-1 hover-scale-sm  text-uppercase font-weight-normal"
                  style={{ fontSize: '12px', width: '40px' }}
                  icon={
                    <FontAwesomeIcon
                      className="mb-1 mt-1  d-24"
                      icon={['fas', `${mode.icon}`]}
                    />
                  }
                  label={<span style={{ letterSpacing: 1 }}>{mode.label}</span>}
                />
              ))}
            </Tabs>
          </div>

          <div className="divider my-3" />

          <div>
            <span className="font-size-md font-weight-bold">Load</span>
          </div>
          <div className={Style.OneFieldContainer}>
            <Card className="rounded-xs">
              {fetchCargoCategories(TRANSPORT_MODES_LIST[0]) &&
                fetchCargoCategories(TRANSPORT_MODES_LIST[0]).length > 0 && (
                  <ToggleButtonGroup
                    exclusive
                    value={filterData.load_type}
                    onChange={(e, value) =>
                      setFilterData({ ...filterData, load_type: value })
                    }
                    className="w-100 ">
                    {fetchCargoCategories(TRANSPORT_MODES_LIST[0]).map(
                      (ccData, index) => {
                        return (
                          <ToggleButton
                            key={index}
                            value={ccData}
                            className="toggle-btn-mui">
                            <span className="text-truncate font-weight-bold">
                              <img className="mr-2" src={ccData.icon} />
                              {ccData.label}
                            </span>
                          </ToggleButton>
                        );
                      }
                    )}
                  </ToggleButtonGroup>
                )}
            </Card>
          </div> */}

        <div className="divider my-3" />

        <div>
          <span className="font-size-md font-weight-bold">Carriers</span>
        </div>
        <div className={Style.TwoFieldContainer} style={{ gap: '10px' }}>
          {transportLineOption.map((option, index) => (
            <Tooltip
              placement="top"
              title={option.label}
              arrow
              style={{ width: 'max-content' }}
              classes={{ tooltip: 'tooltip-kargo-blue' }}>
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px'
                }}>
                <input
                  type="checkbox"
                  value={option.value}
                  checked={filterData.carriers.includes(option.value)}
                  onChange={handleCarrierSelection}
                />
                <span className="font-size-sm text-black text-truncate">
                  {option.label}
                </span>
              </label>
            </Tooltip>
          ))}
        </div>

        <div className="divider my-3" />

        <Button className="btn-primary" onClick={handleFilter}>
          Filter
        </Button>
      </div>
    </>
  );
};

export default ShipmentFilter;
