import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import ConstantValuesService from 'service/ConstantValuesService'
import { Autocomplete } from '@material-ui/lab';
import { FormHelperText, TextField, InputLabel } from '@material-ui/core';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        width: '100%'
    },
    dropdown: {
        position: 'relative',
        width: '100%'
    },
    errorMsg: {
        color: '#f44336',
        fontSize: '12px',
        margin: '8px 14px 0'
    },
    errorBorder: {
        border: '1px solid red'
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18
        }
    }
});
const PortCodeSelect = ({ classes, city = undefined, transportMode = undefined, value = undefined, handleChange, showError = false, error = "", onBlur }) => {

    const [portCode, setPortCode] = useState([])
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (city) {
            setLoading(true)
            ConstantValuesService.fetchPortCodes(city, "", transportMode).then(res => {
                if (res.error) {
                    console.log(res.error)
                } else {
                    setOptions(res)
                    setLoading(false)
                }
            })
        }
        else {
            setOptions([])
        }
    }, [city])




    return (
        <>
            {loading && <LoaderComponent loading={loading} />}
            <div className={classes.root}>
                <InputLabel htmlFor={"Select Port*"}></InputLabel>
                <Autocomplete
                    style={{ width: "100%" }}
                    size='small'
                    value={value}
                    id="combo-box-demo"
                    options={options}
                    onChange={handleChange}
                    onBlur={onBlur}
                    // closeIcon={false}
                    renderOption={(option) => (
                        <div className="d-flex align-items-center font-size-md" style={{gap:"0.25rem"}}>
                            <span className="font-weight-bold">
                                {option.name}
                            </span>
                            <span>|</span>
                            <span className="text-uppercase text-gray-700">{option.code}</span>
                            {option.city && <>
                            <span>|</span>
                                <span className="text-capitalize">{option.city}</span>
                            </>}
                        </div>
                    )}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name={"Port"}
                            label={"Select Port*"}
                            variant={"outlined"}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password' // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                {showError && error && (
                    <FormHelperText variant="outlined" error>
                        {error}
                    </FormHelperText>
                )}
            </div>
            {/* <Select
                style={{width:"200px"}}
                value={value}
                options={options}
                onChange={handleChange}
                placeholder={"Select State"}
            /> */}
        </>
    )
}

export default compose(withStyles(styles))(PortCodeSelect);
// export default PortCodeSelect