import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import { ThemeProvider } from '@material-ui/styles';
import EnterEmail from 'sites/shipper/pages/Forgot Password/EnterEmail/EnterEmail';
import EnterPassword from 'sites/shipper/pages/Forgot Password/EnterPassword/EnterPassword';
import MuiTheme from './theme';

// Layout Blueprints

import { MinimalLayout, PresentationLayout } from './layout-blueprints';

// Error Pages

import PageError404 from './example-pages/PageError404';
import PageError500 from './example-pages/PageError500';
import PageError505 from './example-pages/PageError505';
import ForwarderRoute from 'sites/shipper/components/ForwarderRoute';
import PageError403 from 'components/PageError403';

import ShipmentRates from 'sites/shipper/pages/shipment_rates/ShipmentRates';
import ShippingLeads from 'sites/shipper/pages/ShippingLeads/ShippingLeads';
import CandC from 'sites/shipper/pages/FreightQuote/c_and_c/CandC';
import MakeOffer from 'sites/shipper/pages/ShippingLeads/MakeOffer/MakeOffer';
import MobileChat from 'sites/shipper/pages/Chat/MobileChat/MobileChat';
import MobileChatView from 'sites/shipper/pages/Chat/MobileChat/MobileChatView/MobileChatView';
import MobileShipmentDetails from 'sites/shipper/pages/Chat/MobileChat/MobileShipmentDetails/MobileShipmentDetails';
import MobileShipmentCardPreview from 'sites/shipper/pages/Shipments/ShipmentCardPreview/MobileShipmentCardPreview/MobileShipmentCardPreview';
import TeamMemberRegistartion from 'sites/shipper/pages/Register/TeamMemberRegistration/teamMemberRegistartion';
import WaitingRoom from 'sites/shipper/pages/WaitingRoom/WaitingRoom';
import DocumentPreviewPage from 'sites/shipper/pages/Document/DocumentPreviewPage/DocumentPreviewPage';


// Lazy Load Actual Pages

// Shipper Pages
const ShipperRegister = lazy(() => import('./sites/shipper/pages/Register'));
const ShipperRegisterEmailVerify = lazy(() => import('./sites/shipper/pages/Register/EmailVerifyRegistartion/EmailVerifyRegistartion'));
const ShipperRegisterReferral = lazy(() =>
  import('./sites/shipper/pages/RegisterReferral')
);
const ShipperLogin = lazy(() => import('./sites/shipper/pages/Login'));
const ShipperLogout = lazy(() => import('./sites/shipper/pages/Logout'));
const ShipperRecover = lazy(() =>
  import('./sites/shipper/pages/RecoverPassword')
);
const ShipperResetPassword = lazy(() =>
  import('./sites/shipper/pages/ResetPassword')
);
const ShipperHome = lazy(() => import('./sites/shipper/pages/Home'));
const ShippingLeadsWebsite = lazy(() => import('./sites/shipper/pages/ShippingLeads/ShippingLeadsWebsite'));


const Routes = () => {
  const location = useLocation();
  console.log('Inside Main routes', location);

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the data
                  {/* <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span> */}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            {/* ------------routes created by Werbooz-Akshay Mundra------------  */}

            <Route
              exact
              path="/shipment/shipment-details/id"
              component={MobileShipmentCardPreview}
            />

            {/* preview route for document and images  */}
            <Route path="/preview" component={DocumentPreviewPage} />

            {/* main routes  */}
            <Redirect exact from="/" to="/dashboard" />

            <Route
              path={[
                '/login',
                '/logout',
                '/register',
                '/forgot-password',
                '/forgot-password-password/:id',
                '/team-member-registration/:token',
                '/waiting-room/:type',

                '/register/:referralCode',
                '/recover',
                '/reset/:token',
                '/app/forwarder/login',
                '/app/forwarder/logout',
                '/app/forwarder/register',
                '/app/forwarder/recover',
                '/app/forwarder/reset/:token',
                '/error403',
                '/error404',
                '/error500',
                '/error505',
                '/shipping/list'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/login" component={ShipperLogin} />
                    <Route path="/logout" component={ShipperLogout} />
                    <Route
                      exact
                      path="/register/:referralCode"
                      component={ShipperRegisterReferral}
                    />
                    <Route exact path="/register" component={ShipperRegister} />
                    <Route
                      exact
                      path="/team-member-registration/:token"
                      component={TeamMemberRegistartion}
                    />

                    {/* <Route path="/recover" component={ShipperRecover} />
                    <Route
                      path="/reset/:token"
                      component={ShipperResetPassword}
                    /> */}
                    <Route
                      exact
                      path="/forgot-password"
                      component={EnterEmail}
                    />
                    <Route
                      exact
                      path="/forgot-password-password/:id"
                      component={EnterPassword}
                    />
                    <Route
                      exact
                      path="/waiting-room/:type"
                      component={WaitingRoom}
                    />
                    <Route
                      exact
                      path="/shipping/list"
                      component={ShippingLeadsWebsite}
                    />

                    <Route path="/error403" component={PageError403} />
                    <Route path="/error404" component={PageError404} />
                    <Route path="/error500" component={PageError500} />
                    <Route path="/error505" component={PageError505} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>

            {/* routes created by rajkumar jaiswal */}
            <Route path="/app/forwarder/logout" component={ShipperLogin} />

            <Route exact path="/login" component={ShipperLogin} />
            <Route exact path="/forwarder/login" component={ShipperLogin} />
            <Route exact path="/register" component={ShipperRegister} />
            <Route
              path="/forwarder/register/verify/:registrationTokenId"
              component={ShipperRegisterEmailVerify}
            />

            {/* <ForwarderRoute
              path={['/m/mailBox/message']}
              hideNav
              hideHeader
              component={ShipperHome}
            /> */}
            <ForwarderRoute
              path={['/mobi-chat/id/shipment-details']}
              hideNav
              hideHeader
              component={ShipperHome}
            />
            {/* <ForwarderRoute
              path={['/m/mailBox']}
              hideNav
              hideHeader
              component={ShipperHome}
            /> */}

            <ForwarderRoute
              path={['/c/onboarding']}
              hideNav
              hideHeader
              component={ShipperHome}
            />
            <ForwarderRoute
              path={['/b/onboarding']}
              hideNav
              hideHeader
              component={ShipperHome}
            />

            <ForwarderRoute
              path={['/tools/rate-sheet']}
              hideNav
              component={ShipperHome}
            />
            <ForwarderRoute
              path={['/direct-payout/onboarding/callback']}
              hideNav
              component={ShipperHome}
            />
            
            <ForwarderRoute
              path={['/shipment-leads']}
              hideNav
              component={ShipperHome}
            />
            <ForwarderRoute
              path={['/tools/shippent-leads/make-offer/:id']}
              hideNav
              component={ShipperHome}
            />
             <ForwarderRoute
              path={['/smart-documents/:route']}
              hideNav
              component={ShipperHome}
            />
              <ForwarderRoute
              path={['/smart-documents']}
              hideNav
              component={ShipperHome}
            />
  <ForwarderRoute path={['/shipment-detail/:shipment_id']} hideNav component={ShipperHome} />
            <ForwarderRoute
              path={['/dashboard', '/']}
              component={ShipperHome}
            />

            <Route
              render={() => {
                console.log('No such route');
                return <Redirect to={'/error404'} />;
              }}
            />
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
