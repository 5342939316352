import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';

export default class AuthService {
  static async check2FA(email, password) {
    const url = 'forwarder/signin-check';
    const payload = {
      email: email,
      password: password
    };
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    return result;
    // return HttpService.request(HttpMethods.POST, url, payload)
    // .then((res) => res)
    // .catch((err) => console.error(err));
  }


  static async isRegistrationTokenValid(token) {
    const url = `user/is-registration-token-valid/FWD/${token}`;
    const response = await fetch(getBaseUrl() + url);
    const result = await response.json();
    return result;
  }

  
  static async loginWithGoogle(user,token) {
    const url = 'forwarder/signin-with-google';
    const payload = {
     user
    };
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    const result = await response.json();
    return result;

  }

  static login(email, password, accountType) {
    let url;

    if (accountType === 'SHIPPER') {
      url = 'user/shipper-sign-in';
    }
    if (accountType === 'FORWARDER') {
      url = 'user/forwarder-sign-in';
    }
    const payload = {
      email: email,
      password: password
    };
    return HttpService.request(HttpMethods.POST, url, payload)
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static existingEmail(email, type) {
    const url = 'user/exists';

    const payload = {
      collectionName: type,
      email: email
    };
    return HttpService.request(HttpMethods.POST, url, payload)
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static async fetch_forwarder_kyc_documents(ownerId,cmpyId, token) {
    const url = `forwarder/fetch-forwarder-kyc-documents?ownerId=${ownerId}&cmpyId=${cmpyId}`;
    const response = await fetch(getBaseUrl() + url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await response.json();
    return result;
  }

  static async forgotPasswordMail(email) {
    const url = 'forwarder/fpSendMail';
    const payload = {
      email: email
    };
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    return result;
  }

  static async forgotPasswordEnterPassword(data) {
    const url = 'forwarder/fpChangePassword';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    return result;
  }

  static async updateForwarderInfo(data,token) {
    const url = 'forwarder/updateForwarderInfo';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    const result = await response.json();
    return result;
  }

  static async uploadProfileAvtar(data,token) {
    const url = 'forwarder/uploadProfileAvtar';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    const result = await response.json();
    return result;
  }

  static async deleteProfileAvtar(data,token) {
    const url = 'forwarder/deleteProfileAvtar';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    const result = await response.json();
    return result;
  }

  static async uploadCompanyLogo(data,token) {
    const url = 'forwarder/uploadCompanyLogo';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    const result = await response.json();
    return result;
  }

  static async deleteCompanyLogo(data,token) {
    const url = 'forwarder/deleteCompanyLogo';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    const result = await response.json();
    return result;
  }

  static isAuthenticated(user) {
    return user;
  }

  static sendVerificationCode(payload) {
    return HttpService.request(
      HttpMethods.POST,
      'verify/sendCode',
      payload
    ).then((res) => {
      return res;
    });
  }

  static verifyCode(payload) {
    return HttpService.request(
      HttpMethods.POST,
      'verify/checkCode',
      payload
    ).then((res) => {
      return res;
    });
  }

  static sendPwdResetLink(payload) {
    return HttpService.request(HttpMethods.POST, 'resetPwdLink', payload).then(
      (res) => {
        return res;
      }
    );
  }

  static verifyPwdResetToken(token) {
    return HttpService.request(HttpMethods.GET, `resetPwdLink/${token}`).then(
      (res) => {
        return res;
      }
    );
  }

  static resetPwd(payload) {
    return HttpService.request(HttpMethods.POST, 'resetPwd', payload).then(
      (res) => {
        return res;
      }
    );
  }

  static async logout(token) {
    const url = `${getBaseUrl()}user/signout?role=FORWARDER`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await response.json();
    return result;

    // return HttpService.request(
    //   HttpMethods.GET,
    //   'user/signout?role=FORWARDER',
    //   {},
    //   {},
    //   token
    // ).then((res) => res);
  }
}
