import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

export const upload_to_s3 = async (file, folder) => {
  console.log(file);
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    Key: `${folder}/${file.name}`,
    Body: file,
    ContentType: file.type
  };
  try {
    const data = await s3.upload(params).promise();
    console.log(data.Location);
    return data.Location;
  } catch (error) {
    console.error('Error uploading to S3:', error);
    throw error;
  }
};

export const deleteFileFromS3 = async (fileUrl) => {
  const urlParts = fileUrl.split('/');
  const keyParts = [urlParts[3], urlParts[4], urlParts[5]];
  const fileKey = keyParts.join('/');
  const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      Key: fileKey,
  };
  try {
      await s3.deleteObject(params).promise();
      console.log('File deleted successfully');
  } catch (error) {
      console.error('Error deleting file:', error);
  }
};
