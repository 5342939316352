import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';

import compose from 'recompose/compose';
import { withRouter } from 'react-router';

import { Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import CustomButton from 'components/CustomButton/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import CustomChip from 'sites/shipper/components/CustomChip/CustomChip';

const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  label: {},
  value: {
    width: '80%'
  }
});

const EditableForm = ({
  classes,
  children,
  id,
  label,
  description,
  value,
  onSave,
  onCancel,
  btnCallback,
  canEdit,
  showDots = false,
  onDelete,
  onEditClick,
  isPrimary,
  onMakePrimary,
  showChip = false,
  chipText = ""
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const [action, setAction] = useState();
  const toggleForm = () => {
    setAction('toggle');
    setDisplayForm(!displayForm);
  };

  const saveForm = () => {
    onSave();
    setAction('save');
    setDisplayForm(false);
  };

  useEffect(() => {
    if (action && action === 'toggle' && !displayForm) {
      onCancel();
    }
    if (displayForm) {
      btnCallback(id);
    } else {
      btnCallback();
    }
  }, [displayForm, action]);


  const [openMoreOptions, setOpenMoreOptions] = useState(null);

  const handleCloseOpenMoreOption = () => {
    setOpenMoreOptions(null);
  }


  return (
    <div className={onSave && 'c-py-1'}>
      <div className="c-flex c-justify-content-between c-items-center">
        <span className="c-font-small">{label}</span>


        {canEdit && <>
          {displayForm ? (
            <CustomButton
              onClick={toggleForm}
              style={{ fontSize: '0.8rem' }}>
              Cancel
            </CustomButton>
          ) : (
            <div style={{ display: "flex", gap: "10px",alignItems:"center" }}>

              {showChip && <div> <CustomChip variant={"filled"} color={"dark"} name={chipText} rounded /></div>}

              {showDots ? <>
                <IconButton
                  onClick={(e) => {
                    setOpenMoreOptions(e.currentTarget)
                  }}
                >
                  <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                </IconButton>
                <SmallPopupWrapper
                  open={openMoreOptions}
                  onClose={handleCloseOpenMoreOption}
                >
                  <MenuItem onClick={() => { handleCloseOpenMoreOption(); toggleForm(); if (onEditClick) { onEditClick() } }}> Edit</MenuItem>
                  {!isPrimary && <MenuItem onClick={() => { handleCloseOpenMoreOption(); onMakePrimary() }}>Make Primary</MenuItem>}
                  {(onDelete && !isPrimary) && <MenuItem onClick={() => { handleCloseOpenMoreOption(); onDelete() }}>Delete</MenuItem>}
                </SmallPopupWrapper>
              </> :
                <CustomButton
                  onClick={() => { toggleForm(); if (onEditClick) { onEditClick() } }}
                  icon={<Edit style={{ fontSize: '0.8rem' }} />}
                  style={{ fontSize: '0.8rem' }}
                  tooltip="Edit">
                  Edit
                </CustomButton>
              }
              {/* {isDeleteAble &&
                <CustomButton
                  onClick={onDelete}
                  icon={<DeleteIcon style={{ fontSize: '0.8rem' }} />}
                  style={{ fontSize: '0.8rem' }}
                  tooltip="Edit">
                  Delete
                </CustomButton>
              } */}

            </div>
          )} </>}

      </div>
      {!displayForm && (
        <div className="c-font-title c-font-light">
          {value && value.trim().length > 0 ? (
            <span>{value}</span>
          ) : (
            <span className="text-black-50">{'Not Provided'}</span>
          )}
        </div>
      )}
      {displayForm && canEdit && (
        <>
          {description && <p className="c-font-title c-font-light">{description}</p>}
          <div>{children}</div>
          <div className="c-mt-1">
            <Button
              size="small"
              onClick={saveForm}
              type="submit"
              className='btn-primary'
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

EditableForm.defaultProps = {
  canEdit: true,
  description: undefined,
  children: '',
  btnCallback: () => { }
};

EditableForm.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  canEdit: PropTypes.bool
};

export default compose(
  withStyles(styles),
  withRouter,
  withWidth()
)(EditableForm);
{/* {canEdit && (
          <Button
            onClick={toggleForm}
            disabled={!canEdit}
            className="btn-link btn-link-primary font-weight-bold p-0"
            title={displayForm ? 'Cancel' : 'Edit'}>
            {!displayForm && (
              <Edit  icon={<Edit sx={{ fontSize: '0.8rem' }} />}
                  style={{ fontSize: '0.8rem' }} />
            )}
            <span>{displayForm ? 'Cancel' : 'Edit'}</span>
          </Button>
        )} */}