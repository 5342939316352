
import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';


export default class ConstantValuesService {
    static async fetchCountryList(query) {
        const url = `${getBaseUrl()}constants/fetch-country?query=${query}`
        const response = await fetch(url)
        const result = await response.json()
        return result
    }


    static async fetchStateList(country = "", query = "") {
        const url = `${getBaseUrl()}constants/fetch-state?country=${country}&query=${query}`
        const response = await fetch(url)
        const result = await response.json()
        return result
    }



    static async fetchCityList(state = "", query = "") {
        const url = `${getBaseUrl()}constants/fetch-city?state=${state}&query=${query}`
        const response = await fetch(url)
        const result = await response.json()
        return result
    }

    static async fetchPortCodes(city = "", query = "", transportMode = "") {
        const url = `${getBaseUrl()}constants/fetch-port-code?city=${city}&query=${query}&transportMode=${transportMode}`
        const response = await fetch(url)
        const result = await response.json()
        return result
    }
    static async fetchHsCodeCategory() {
        const url = `${getBaseUrl()}constants/fetch-hs-code-category`
        const response = await fetch(url)
        const result = await response.json()
        return result
    }

    static async fetchHsCodeName(category) {
        const url = `${getBaseUrl()}constants/fetch-hs-code-name?category=${category}`
        const response = await fetch(url)
        const result = await response.json()
        return result
    }

    static fetchTransportLine(transport_mode) {
        return HttpService.request(HttpMethods.GET, `constants/fetch-carrier?transport_mode=${transport_mode}`).then(
            (res) => res
        );
    }


    static fetchDocumentList(applicable_for, applicable_in, show_in_overview) {
        return HttpService.request(HttpMethods.GET, `constants/document-fetch?applicable_for=${applicable_for}&applicable_in=${applicable_in}&show_in_overview=${show_in_overview}`).then(
            (res) => res
        );
    }
    
    static fetchCompanyList(query) {
        return HttpService.request(HttpMethods.GET, `tool/fetch-company?query=${query}`).then(
            (res) => res
        );
    }

}

